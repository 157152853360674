import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmComponent } from '../components/modal/modal-confirm/modal-confirm.component';
import { ModalMessageComponent } from '../components/modal/modal-message/modal-message.component';
import { ModalPassengerErrorComponent } from '../components/modal/modal-passenger-error/modal-passenger-error.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    public dialog: MatDialog
  ) { }

  /**
   * 
   * Open modal errors passengers
   * 
   */
  openPassengers(data,products,width="600px",height="auto"){  

    this.dialog.open(ModalPassengerErrorComponent,{ 
      width: width,
      height: height,
      data:{
        data: data,
        products: products
      }
    });

  }
  /**
   * 
   * Open
   * 
   */
  open(message="",width='350px',height='auto'){


    this.dialog.open(ModalMessageComponent,{ 
      width: width,
      height: height,
      data:{
        message: message
      }
    });

  }
  /**
   * 
   * Info
   * 
   */
   confirm(
    message="",
    self=null,
    dataItem={},
    width='350px',
    height='auto'
  ){
    
    this.dialog.open(ModalConfirmComponent,{ 
      width: width,
      height: height,
      data:{
        message: message,
        dataItem: dataItem,
        self: self
      }
    });
    
  }

}
