<div class="dm-modal">
    <div class="modal-title">
      <span class="text">Atenção</span>
    </div>  
    <div class="modal-body">
      <div class="product" *ngFor="let p of data">
        <h3 class="title-h3">{{p.name}}</h3>
        <div class="linha"></div>
        <div class="item" *ngFor="let m of p.messages">
          <h4 class="title-h4">{{m.title}}</h4>
          <div class="list-errors">
            <div class="error" *ngFor="let e of getArray(m.errors)">
              <span>{{e}}</span>
            </div>  
          </div>  
        </div>  
      </div>
      <div class="product" *ngFor="let p of products">
        <h3 class="title-h3">{{p.name}}</h3>
        <div class="linha"></div>
        <div class="item" *ngFor="let m of p.messages">
          <div class="list-errors">
            <div class="error" *ngFor="let e of getArray(m.errors)">
              <span>{{e}}</span>
            </div>  
          </div>  
        </div>
      </div>  
    </div>
    <div class="modal-footer">
      <div class="center">
        <button type="button" class="btn btn-one btn-icon" (click)="close()">
            <i class="material-icons">check_circle</i>
            <span>OK</span>
        </button> 
      </div>
    </div>   
</div>
  