export class Routes {

    public static BASE = "/api";

    public static auth = {
        login: Routes.BASE+"/auth/login",
        logout: Routes.BASE+"/auth/logout"
    }
    public static logs = {
        log: {
            url: Routes.BASE+"/logs/log"
        }
    }
    public static flight = {
        airport: {
            url: Routes.BASE+"/flight/airport",
            combo: Routes.BASE+"/flight/airport/combo"
        },
        airline: {
            url: Routes.BASE+"/flight/airline",
            combo: Routes.BASE+"/flight/airline/combo"
        }
    }
    public static report = {
        dashboard: {
            home: {
                url: Routes.BASE+"/report/dashboard/home"  
            },
            sale_month_year: { 
                url: Routes.BASE+"/report/dashboard/sale-month-year"     
            },
            sale_services: { 
                url: Routes.BASE+"/report/dashboard/sale-services"     
            },
            sale_categories: { 
                url: Routes.BASE+"/report/dashboard/sale-categories"     
            },
            sale_list_users: { 
                url: Routes.BASE+"/report/dashboard/sale-list-users"     
            }
        }
    }
    public static products = {
        product: {
            list: {
                url: Routes.BASE+"/products/list"
            },
            show: {
                url: Routes.BASE+"/products/product"
            },
            types: {
                url: Routes.BASE+"/products/types"
            },
            disponibility: {
                url: Routes.BASE+"/products/disponibility"
            },
            hotels: {
                url: Routes.BASE+"/products/hotels"
            },
            destination: {
                url: Routes.BASE+"/products/destinations"
            }
        }
    }
    public static paymentLink = {
        order: {
            url: Routes.BASE+"/payment-link/order" 
        }
    }
    public static sale = {
        payment_link: {
            url: Routes.BASE+"/sale/payment-link"
        },
        cart: {
            url: Routes.BASE+"/sale/cart",
            download: Routes.BASE+"/sale/cart/download",
            print: Routes.BASE+"/sale/cart/print",
            share_email: Routes.BASE+"/sale/cart/share/email",
            new: Routes.BASE+"/sale/cart/new"  
        },
        order: {
            order: {
                url: Routes.BASE+"/sale/order",
                reopen: Routes.BASE+"/sale/order/reopen",
                user_by_cpf: Routes.BASE+"/sale/order/user-by-cpf",
                voucher:{
                    download: Routes.BASE+"/sale/order/voucher/download",
                    email: Routes.BASE+"/sale/order/voucher/email"
                }
            },
            payment: { 
                url: Routes.BASE+"/sale/order/payment"
            },
            faturar: { 
                url: Routes.BASE+"/sale/order/faturar"
            }
        }
    }
    public static register = {
        user: {
            combo: Routes.BASE+"/register/user/combo", 
            show: Routes.BASE+"/register/user/show",
            profile: Routes.BASE+"/register/user/profile",
            url: Routes.BASE+"/register/user"
        }
    }
    public static files = {  
        upload: Routes.BASE+"/files/upload", // POST
        read: Routes.BASE+"/files", // GET
        delete: Routes.BASE+"/files", // DELETE
        combo: Routes.BASE+"/files/combo", // GET
        crop: Routes.BASE+"/files/crop" // POST
    } 
    public static notification = {  
        partner: Routes.BASE+"/notification/partner"  
    } 

}