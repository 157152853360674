import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSidenavModule, MatDrawer} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatNativeDateModule,MAT_DATE_LOCALE} from '@angular/material/core';

@NgModule({
    imports: [
        CommonModule,
        MatCheckboxModule,
        MatInputModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatSliderModule,
        MatCardModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatIconModule,
        MatTabsModule,
        MatDialogModule,
        MatSidenavModule,
        MatMenuModule,
        MatButtonModule,
        MatToolbarModule,
        MatMenuModule,
        MatTooltipModule,
        MatNativeDateModule
    ],
    declarations: [],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }
    ],
    exports: [
        CommonModule,
        MatCheckboxModule,
        MatInputModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatCardModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatIconModule,
        MatTabsModule,
        MatDialogModule,
        MatSidenavModule,
        MatMenuModule,
        MatButtonModule,
        MatToolbarModule,
        MatTooltipModule
    ]
})
export class MaterialModule { }
