import { CommonModule } from '@angular/common';
import { Compiler, Component, ComponentFactory, EventEmitter, Injectable, ModuleWithComponentFactories, NgModule, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StorageService } from './storage.service';

declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public updateUser = new EventEmitter(); 

  constructor(
    private dialog: MatDialog,
    private compiler: Compiler,
    private storage: StorageService
  ){ }

  /**
   * 
   * Formatar Valor
   * 
   * @param value 
   * 
   */
   formatarValor(value){

    value = value.replace(new RegExp(/[.]/g),"");
    value = value.replace(new RegExp(/[,]/g),".");

    return parseFloat(value);

  }
  /**
   * 
   * Remove acentos
   * 
   */
   removerAcentos(value){

    try{
      var string = value;
      var mapaAcentosHex 	= {
          a : /[\xE0-\xE6]/g,
          A : /[\xC0-\xC6]/g,
          e : /[\xE8-\xEB]/g,
          E : /[\xC8-\xCB]/g,
          i : /[\xEC-\xEF]/g,
          I : /[\xCC-\xCF]/g,
          o : /[\xF2-\xF6]/g,
          O : /[\xD2-\xD6]/g,
          u : /[\xF9-\xFC]/g,
          U : /[\xD9-\xDC]/g,
          c : /\xE7/g,
          C : /\xC7/g,
          n : /\xF1/g,
          N : /\xD1/g
      };

      for ( var letra in mapaAcentosHex ) {
          var expressaoRegular = mapaAcentosHex[letra];
          string = string.replace( expressaoRegular, letra );
      }

     return string; 
    }catch(e){
      return value;
    }

  }
  /**
   * 
   * Info
   * 
   */
   info(message="",tipo='info',width='350px',height='auto'){

    alert(message); 
    /*this.dialog.open(MatModalInfoComponent,{ 
      width: width,
      height: height,
      data:{
        message: message,
        tipo: tipo
      }
    });*/

  }
  /** 
   * 
   * Cria um Component Runtime
   * 
   */
   createComponentFactorySync(compiler: Compiler, metadata: Component, componentClass: any):ComponentFactory<any> {
  
    const cmpClass = componentClass || class RuntimeComponent { };
    const decoratedCmp = Component(metadata)(cmpClass);
 
    const decoratedNgModule = NgModule({
      imports:[
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
      ], declarations: [decoratedCmp] })(cmpClass);

    const module: ModuleWithComponentFactories<any> = compiler.compileModuleAndAllComponentsSync(decoratedNgModule);
     
    return module.componentFactories.find(f => f.componentType === decoratedCmp);
 
  }  
  /**
   * 
   * Compila um template
   * 
   */
  compileTemplate(
    html,
    container:ViewContainerRef,
    Class:any=null,
    isContext=true,
    dataItem=null,
    grid=null,
    data=null
  ){
    
    let factory = null;

    let metadata = {
      selector: `runtime-component-sample`,
      template: html
    };

    if(Class != null && isContext){
      
      let context = Class;
  
      Class = class RuntimeComponent{

        context  = context;
        dataItem = dataItem;
        data     = data;
        grid     = grid;
 
      };

    }

    factory = this.createComponentFactorySync(this.compiler,metadata,Class);       
    container.createComponent(factory);

  }
  /**
   * 
   * Envia para o topo
   * 
   * 
   */
   toTop(){ 
         
    $('html, body').stop().animate({ 
      scrollTop: 0
    }, 500);
   

  }
  /**
   * 
   * 
   * 
   */
  permiteFaturar(){

    let user = this.storage.getUser();

    if(user != null){
      return user.permiteFaturar == true || user.permiteFaturar == 'true' ? true : false;
    }else{
      return false;
    }

  }
  
}
