import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentOrderComponent } from 'src/app/components/sale/payment/payment-order/payment-order.component';
import { PartialModule } from '../partial/partial.module';
import { FormModule } from '../form/form.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaymentOrderPassengerComponent } from 'src/app/components/sale/payment/payment-order-passenger/payment-order-passenger.component';
import { PipeModule } from '../pipe/pipe.module';
import { DirectiveModule } from '../directive/directive.module';
import { PaymentCardComponent } from '../../components/sale/payment/payment-card/payment-card.component';
import { ComboPassengersComponent } from '../../components/sale/cart/combo/combo-passengers/combo-passengers.component';
import { ComboInstallmentsComponent } from '../../components/sale/cart/combo/combo-installments/combo-installments.component';
import { ComboTitularPassengerComponent } from '../../components/sale/cart/combo/combo-titular-passenger/combo-titular-passenger.component';
import { PaymentFileErrorModalComponent } from '../../components/sale/payment/modal/payment-file-error-modal/payment-file-error-modal.component';
import { PaymentErrorModalComponent } from '../../components/sale/payment/modal/payment-error-modal/payment-error-modal.component';
import { PaymentOrderLinkComponent } from '../../components/sale/payment/payment-order-link/payment-order-link.component';
import { CartModule } from '../cart/cart.module';
import { CartFlightModule } from '../cart/cart-flight/cart-flight.module';

@NgModule({
    declarations: [
        PaymentOrderComponent,
        PaymentOrderPassengerComponent,
        PaymentCardComponent,
        ComboPassengersComponent,
        ComboInstallmentsComponent,
        ComboTitularPassengerComponent,
        PaymentFileErrorModalComponent,
        PaymentErrorModalComponent,
        PaymentOrderLinkComponent
    ],
    exports: [
        PaymentOrderComponent,
        PaymentOrderPassengerComponent,
        PaymentCardComponent,
        ComboPassengersComponent,
        ComboInstallmentsComponent,
        ComboTitularPassengerComponent,
        PaymentFileErrorModalComponent,
        PaymentErrorModalComponent,
        PaymentOrderLinkComponent
    ],
    imports: [
        CommonModule,
        PartialModule,
        FormModule,
        FormsModule,
        ReactiveFormsModule,
        PipeModule,
        DirectiveModule,
        CartFlightModule
    ]
})
export class PaymentModule { }
