import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CartAddSucessModalComponent } from '../components/sale/cart/modal/cart-add-sucess-modal/cart-add-sucess-modal.component';
import { CartDeleteModalComponent } from '../components/sale/cart/modal/cart-delete-modal/cart-delete-modal.component';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public updateData  = new EventEmitter();
  public updateTotal = new EventEmitter();

  constructor(
    private dialog: MatDialog
  ) { }

  /**
   * 
   * Format product to list
   * 
   * 
   */
   formatProductToList(products){

    let list = [];

    for (let i = 0; i < products.length; i++) {  
      
      if(products[i].item.flight_require){
        list.push(products[i].item); 
      }  
        
      
    }

    return list;

  }
  /**
   * 
   * Format passengers to list
   * 
   * 
   */
  formatPassengersToList(products){

    let list = [];

    for (let i = 0; i < products.length; i++) {  
      
      for (let y = 0;y < products[i].passengers.length;y++) {
   
        list.push({
          item_id: products[i].item.id,
          name: products[i].passengers[y].name,
          last_name: products[i].passengers[y].last_name,
          document: products[i].passengers[y].document,
          type: products[i].passengers[y].type
        });
        
      }
      
    }

    return list;

  }
  /**
   * 
   * Sucess modal
   * 
   */
  sucessModal(name){

    this.dialog.open(CartAddSucessModalComponent,{
      width: "400px",
      data: {
        name: name
      }
    });

  }
  /**
   * 
   * Sucess modal
   * 
   */
   deleteModal(name,id){

    this.dialog.open(CartDeleteModalComponent,{
      width: "400px",
      data: {
        name: name,
        id: id
      }
    });

  }

}
