<div class="sidebar sidebar-default">
  <div class="content">
    <menu-default></menu-default>
    <div class="by-developer">
      <div class="content">
        <span>Desenvolvido por</span>
        <a href="https://digitalmovement.com.br/" target="_blank" title="Digital Movement - Agência Digital">
          Digital Movement
          <img src="/assets/logo/simbolo.svg" alt="Digital Movement - Agência Digital" width="20px" />
        </a>
      </div>  
    </div>  
  </div>  
</div>
