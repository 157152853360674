import { Component, ElementRef } from '@angular/core';
import { ApiService } from './services/api.service';
import { AppService } from './services/app.service';
import { ModalService } from './services/modal.service';
import { TranslateService } from '@ngx-translate/core';

declare var $:any;
declare var urlMetrix:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    AppService,ApiService,ModalService
  ]
})
export class AppComponent {
  
  title = 'portal';
  public paymentLink = false;
  public metrixRead  = false;

  constructor(
    public translate: TranslateService,
    private elementRef:ElementRef
  ){

    //translate.addLangs(['pt-BR']);
    //translate.setDefaultLang('pt-BR');
    
    let paymentLink = this.elementRef.nativeElement.getAttribute('paymentLink') ? this.elementRef.nativeElement.getAttribute('paymentLink') : false;
    
    if(paymentLink === true || paymentLink === "true" || paymentLink === 1 || paymentLink === "1"){
      this.paymentLink = true;
    }else{
      this.paymentLink = false;
    }

    /*if(!this.metrixRead){ 
      if(typeof(urlMetrix) != "undefined"){
        this.sendMetrix(urlMetrix);
      }
    }
    this.metrixRead = true;*/

  }
  /**
   * 
   * @param url 
   * 
   */
   sendMetrix(url){
    
    /*try{

      $.ajax({
        url: url,
        type: "GET",
        dataType: "jsonp",
        success: function(e){
          
        },
        error: function(e){
          
        }
      });
 
    }catch(e){}*/
 
  }
  

}
