import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { PartialModule } from '../partial/partial.module';
import { TopoComponent } from 'src/app/layout/partials/default/topo/topo.component';
import { SidebarComponent } from 'src/app/layout/partials/default/sidebar/sidebar.component';
import { MenuComponent } from 'src/app/layout/partials/default/menu/menu.component';
import { LogoComponent } from 'src/app/layout/partials/default/logo/logo.component';
import { UserProfileComponent } from 'src/app/layout/partials/user/user-profile/user-profile.component';
import { CartModule } from '../cart/cart.module';
import { NotificationPartialModule } from '../notification/notification-partial/notification-partial.module';

@NgModule({
    imports: [
        CommonModule,
        MatToolbarModule,
        MatSidenavModule,
        MatIconModule,
        AppRoutingModule,
        MatMenuModule,
        PartialModule,
        CartModule,
        NotificationPartialModule
    ],
    declarations: [
        TopoComponent,
        SidebarComponent,
        MenuComponent,
        LogoComponent,
        UserProfileComponent
    ],
    exports: [
        TopoComponent,
        SidebarComponent,
        MenuComponent,
        MatToolbarModule,
        MatSidenavModule,
        LogoComponent,
        UserProfileComponent
    ]
})
export class LayoutModule { }
