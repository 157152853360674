import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalMessageComponent } from './components/modal/modal-message/modal-message.component';
import { ModalConfirmComponent } from './components/modal/modal-confirm/modal-confirm.component';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { MaterialModule } from './modules/material/material.module';
import { ValidatorService } from './services/validator.service';
import { StorageService } from './services/storage.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PartialModule } from './modules/partial/partial.module';
import { LayoutModule } from './modules/layout/layout.module';
import '@angular/compiler';
import { ServicoService } from './services/servico.service'; 
import { CheckoutPaymentPageComponent } from './pages/sales/checkout-payment-page/checkout-payment-page.component';
import { GridService } from './services/grid.service';
import { ModalPassengerErrorComponent } from './components/modal/modal-passenger-error/modal-passenger-error.component';
import { RouteAdminGuard } from './guard/route-admin-guard';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { AppPaymentLinkComponent } from './apps/app-payment-link/app-payment-link.component';

registerLocaleData(localePt,'pt-BR');

@NgModule({
    declarations: [
        AppComponent,
        ModalMessageComponent,
        ModalConfirmComponent,
        DefaultLayoutComponent,
        CheckoutPaymentPageComponent,
        ModalPassengerErrorComponent,
        AppPaymentLinkComponent
    ],
    exports: [
        DefaultLayoutComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        PartialModule,
        LayoutModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        ValidatorService,
        StorageService,
        ServicoService,
        GridService,
        RouteAdminGuard,
        { provide: LOCALE_ID, useValue: 'pt-BR' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


