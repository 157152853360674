import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartFlightDataComponent } from 'src/app/components/sale/cart/cart-flight-data/cart-flight-data.component';
import { PartialModule } from '../../partial/partial.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormModule } from '../../form/form.module';
import { MatCardModule } from '@angular/material/card';
import { DirectiveModule } from '../../directive/directive.module';
import { PipeModule } from '../../pipe/pipe.module';
import { ComboAirportComponent } from 'src/app/components/sale/cart/combo/combo-airport/combo-airport.component';
import { ComboAirlineComponent } from 'src/app/components/sale/cart/combo/combo-airline/combo-airline.component';

@NgModule({
  declarations: [
    CartFlightDataComponent,
    ComboAirportComponent,
    ComboAirlineComponent
  ],
  exports: [
    CartFlightDataComponent,
    ComboAirportComponent,
    ComboAirlineComponent
  ],
  imports: [
    CommonModule,
    CommonModule, 
    PartialModule,
    FormsModule,
    FormModule,
    MatCardModule,
    ReactiveFormsModule,
    PipeModule,
    DirectiveModule  
  ]
})
export class CartFlightModule { }
